<template>
  <div style="background: #f5f5f5;height: 100%;">
    <van-nav-bar title="套餐" left-arrow :fixed="true" :z-index="999" @click-left="back">
      <!-- <van-icon name="plus" slot="right" /> -->
    </van-nav-bar>

    <van-tabs
      v-model="active"
      title-active-color="#333"
      color="#DF3D48"
      style="padding-top:46px;background: #f5f5f5;"
    >
      <van-tab title="可用套餐">
        <div
          v-for="item in validList"
          :key="item.comboCustomerId"
          style="padding:0 12px;border-radius: 4px;"
        >
          <van-cell-group class="m-t-10 p-10 p-l-5 c_xian">
            <van-cell
              :title="item.comboName"
              :icon="tcimg"
              :to="{name: 'meal_info', params: { id: item.comboCustomerId }}"
              style=" height: 30px; line-height: 30px;padding: 0; padding-left: 5px;"
            >
              <em>
                <span style="color:#DF3D48;padding-right:10px;">使用详情</span>
              </em>
            </van-cell>
          </van-cell-group>
          <van-cell-group>
            <van-cell>
              <van-row type="flex" justify="space-between">
                <van-col span="6">办理门店:</van-col>
                <van-col span="18" style="text-align:right;">
                  {{item.garageName}}
                  <sapn class="bian" @click="tobiangen(item)">变更</sapn>
                </van-col>
              </van-row>
            </van-cell>
            <van-cell>
              <van-row type="flex" justify="space-between">
                <van-col span="6">办理车牌:</van-col>
                <van-col span="18" style="text-align:right;">
                  <span v-if="item.isLimitCarNo== 1">（限此车牌使用）</span>
                  {{item.carNo}}
                </van-col>
              </van-row>
            </van-cell>
            <van-cell>
              <van-row type="flex" justify="space-between">
                <van-col span="6">起止日期:</van-col>
                <van-col
                  span="18"
                  style="text-align:right;"
                >{{item.startDate.substring(0,10)+' 至 '+item.endDate.substring(0,10)}}</van-col>
              </van-row>
            </van-cell>

            <van-cell v-for="v in item.comboItemList" :key="v.id">
              <van-row type="flex" justify="space-between">
                <van-col span="8">{{v.itemName}}</van-col>
                <van-col span="6" style="text-align:center;color:#DF3D48;">
                  <span v-if="v.totalTimes <= 9000">{{v.totalTimes}}次</span>
                </van-col>
                <van-col span="6" style="text-align:right;">
                  <span style="vertical-align: middle;">
                    <span v-if="v.totalTimes >= 9000">无限次数</span>
                    <span v-else>剩余{{v.surplusTimes}}次</span>
                  </span>
                  <!-- <van-checkbox
                    class="ches"
                    checked-color="#0D906E"
                    v-if="isok == true"
                    v-model="v.checked"
                  ></van-checkbox>-->
                </van-col>
              </van-row>
            </van-cell>
          </van-cell-group>
        </div>
      </van-tab>
      <van-tab title="失效套餐">
        <div v-for="item in loseList" :key="item.comboCustomerId">
          <van-cell-group class="m-t-10 p-10 p-l-5">
            <van-cell
              :title="item.comboName"
              :icon="tcimg"
              style="border-left: 4px solid #DF3D48;margin-left: 5px; height: 30px; line-height: 30px;padding: 0; padding-left: 5px;"
            >
              <!-- <em><span style="color:#01b185;padding-right:10px;">使用详情</span></em> -->
            </van-cell>
          </van-cell-group>
          <van-cell-group>
            <van-cell
              :title="'购买日期：'+item.startDate.substring(0,10)"
              :value="'有效期至：'+item.endDate.substring(0,10)"
            />
            <van-cell v-for="v in item.comboItemList" :key="v.id">
              <van-row type="flex" justify="space-between">
                <van-col span="8">{{v.itemName}}</van-col>
                <van-col span="6" style="text-align:center;color:#DF3D48;">
                  <span v-if="v.totalTimes <= 9000">{{v.totalTimes}}次</span>
                </van-col>
                <van-col span="6" style="text-align:right;">剩余{{v.surplusTimes}}次</van-col>
              </van-row>
            </van-cell>
          </van-cell-group>
        </div>
      </van-tab>
      <van-tab title="已用套餐">
        <div v-for="item in usedList" :key="item.comboCustomerId">
          <van-cell-group class="m-t-10 p-10 p-l-5">
            <van-cell
              :title="item.comboName"
              :icon="tcimg"
              :to="{name: 'meal_info', params: { id: item.comboCustomerId }}"
              style="border-left: 4px solid #DF3D48;margin-left: 5px; height: 30px; line-height: 30px;padding: 0; padding-left: 5px;"
            >
              <em>
                <span style="color:#DF3D48;padding-right:10px;">使用详情</span>
              </em>
            </van-cell>
          </van-cell-group>
          <van-cell-group>
            <van-cell
              :title="'购买日期：'+item.startDate.substring(0,10)"
              :value="'有效期至：'+item.endDate.substring(0,10)"
            />
            <van-cell v-for="v in item.comboItemList" :key="v.id">
              <van-row type="flex" justify="space-between">
                <van-col span="8">{{v.itemName}}</van-col>
                <van-col span="6" style="text-align:center;color:#DF3D48;">
                  <span v-if="v.totalTimes <= 9000">{{v.totalTimes}}次</span>
                </van-col>
                <van-col span="6" style="text-align:right;">
                  <span>剩余{{v.surplusTimes}}次</span>
                </van-col>
              </van-row>
            </van-cell>
          </van-cell-group>
        </div>
      </van-tab>
    </van-tabs>
    <div style="height: 42px;"></div>
    <van-button class="bottd" type="danger" @click="yuyue" size="normal">预约使用</van-button>
    <!-- <van-button
      class="bottd"
      type="danger"
      v-if="active == 0 && isok== true"
      @click="yuyue"
      size="normal"
    >选择施工时间</van-button>-->
  </div>
</template>

<script>
import userModel from "../../api/user";

export default {
  data() {
    return {
      active: 0,
      isok: false,
      checked: false,
      loseList: [],
      usedList: [],
      validList: [],
      tcimg: require("../../assets/img/tc.png"),
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      userInfo: JSON.parse(localStorage.getItem("userInfo"))
    };
  },
  methods: {
    onLoad() {
      console.log(this.userInfo);
      //   var carNo = this.userInfo.mallUserCarInfoDO?this.userInfo.mallUserCarInfoDO.carNo:''
      //   console.log(carNo)
      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中..."
      });
      userModel
        .findByPhone({
          gid: this.shangjia.id,
          // phone: 15350281269
          phone: this.userInfo ? this.userInfo.phone : ""
        })
        .then(e => {
          loading.clear();
          this.loseList = e.data.loseList;
          this.usedList = e.data.usedList;
          this.validList = e.data.validList;
        })
        .catch(() => loading.clear());
    },
    //变更套餐
    tobiangen(item) {
      this.$router.push({
        path: "Store_list",
        query: {
          isok: false,
          title: "请选择变更后的门店",
          showisok: "meal",
          comboCustomerId: item.comboCustomerId
        }
      });
    },
    yuyue() {
      this.$router.push({
        path: "select"
      });
    },
    back() {
      this.$router.go(-1);
    }
  },
  created() {
    this.onLoad();
  }
};
</script>

<style lang="less" scoped>
.bottd {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 40px;
  border-radius: 20px;
}
.c_xian {
  border-left: 4px solid #df3d48;
}
.ches {
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
}
.van-icon {
  height: 30px;
  vertical-align: middle;
}
.van-icon img {
  font-size: 22px;
  margin-top: 2px;
}
.bian {
  margin-left: 5px;
  color: #07c160;
}
</style>
